import { NexusGenObjects } from "@olivahealth/graphql-server/src/ui/types/graphql.gen";

import EmailLoginForm from "../EmailLoginForm";
import EmailSignUpForm from "../SignUp/EmailSignUpForm";
import { AuthMethod } from "./types";

interface Props {
  isLogin: boolean;
  invitation?: Pick<NexusGenObjects["Invitation"], "id" | "email">;
  toggleAuthMethod: (authMethod: AuthMethod) => () => void;
  setEmail: (email: string) => void;
  displayBackButton?: boolean;
}

export default function EmailPanel({
  isLogin,
  invitation,
  toggleAuthMethod,
  setEmail,
  displayBackButton = true,
}: Props) {
  return isLogin ? (
    <EmailLoginForm toggleAuthMethod={toggleAuthMethod} setEmail={setEmail} />
  ) : (
    <EmailSignUpForm
      invitation={invitation}
      toggleAuthMethod={toggleAuthMethod}
      setEmail={setEmail}
      displayBackButton={displayBackButton}
    />
  );
}
